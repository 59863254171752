import { Module } from 'vuex'

interface ResponseHeadersState {
  list: Map<string, string>
}
const getInitialState = (): ResponseHeadersState => ({
  list: new Map()
})

export default (): Module<ResponseHeadersState, any> => ({
  namespaced: true,
  state: getInitialState(),
  mutations: {
    setHeader (state, { key, value }: { key: string, value: string }) {
      state.list.set(key, value)
    },
    disableCacheControl (state) {
      state.list.set('chrono-cache-control', 'disabled')
    },
  },
})
