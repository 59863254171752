import LoadablePageModel from '@/models-ts/LoadablePageModel'
import {
  getMyCustomerJobList,
  getMyCustomerCompletedJobList, getWorkerList,
} from '@/api/jobs/lists'
import { IMyJobsState } from './types'
import { Module } from 'vuex'
import { RootState } from '@/store'
import {
  STAGE_BLOCKED_BY_FREELANCER,
  STAGE_COMPLETED,
  STAGE_DEADLINE_OVERDUE,
  STAGE_DISPUTED,
  STAGE_IN_PROGRESS,
  STAGE_NEW,
  STAGE_STARTED
} from '@/constants/job/jobStages'
import MyCustomerJobListItem from '@/models-ts/job/MyCustomerJobListItem'
import JobApplication from '@/models-ts/job/JobApplication'
import { JobApplicationStatuses } from '@/constants/job/jobApplicationStatuses'

const getInitialState = (): IMyJobsState => ({
  clientPosted: new LoadablePageModel(),
  clientActive: new LoadablePageModel(),
  clientCompleted: new LoadablePageModel(),
  clientAll: new LoadablePageModel(),
  workerAll: new LoadablePageModel(),
  workerNegotiations: new LoadablePageModel(),
  workerArchived: new LoadablePageModel(),
  workerActive: new LoadablePageModel(),
  workerCompleted: new LoadablePageModel(),
})

const ALL_LIMIT = 1000

export default (): Module<IMyJobsState, RootState> => ({
  namespaced: true,
  state: getInitialState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getInitialState())
    },
    setClientPostedLoading (state) {
      state.clientPosted.loading()
    },
    setClientPostedLoaded (state, data) {
      state.clientPosted.loaded(data)
    },
    addClientPostedLoaded (state, data) {
      state.clientPosted.loadMore(data)
    },
    updateJobStatus (state, { jobId, status, moderationStage }) {
      const lists = [state.clientPosted, state.clientActive, state.clientCompleted, state.clientAll]
      lists.forEach((list) => {
        const job = list.values.find(job => job.id === jobId)
        if (job) {
          if (status) {
            job.status = status
          }
          if (moderationStage) {
            job.moderationStage = moderationStage
          }
        }
      })
    },
    setClientActiveLoading (state) {
      state.clientActive.loading()
    },
    setClientActiveLoaded (state, data) {
      state.clientActive.loaded(data)
    },
    addClientActiveLoaded (state, data) {
      state.clientActive.loadMore(data)
    },
    setClientCompletedLoading (state) {
      state.clientCompleted.loading()
    },
    setClientCompletedLoaded (state, data) {
      state.clientCompleted.loaded(data)
    },
    addClientCompletedLoaded (state, data) {
      state.clientCompleted.loadMore(data)
    },
    setClientAllLoading (state) {
      state.clientAll.loading()
    },
    setClientAllLoaded (state, data) {
      state.clientAll.loaded(data)
    },
    addClientAllLoaded (state, data) {
      state.clientAll.loadMore(data)
    },
    setWorkerAllLoading (state) {
      state.workerAll.loading()
    },
    setWorkerAllLoaded (state, data) {
      state.workerAll.loaded(data)
    },
    addWorkerAllLoaded (state, data) {
      state.workerAll.loadMore(data)
    },
    setWorkerNegotiationsLoading (state) {
      state.workerNegotiations.loading()
    },
    setWorkerNegotiationsLoaded (state, data) {
      state.workerNegotiations.loaded(data)
    },
    addWorkerNegotiationsLoaded (state, data) {
      state.workerNegotiations.loadMore(data)
    },
    setWorkerArchivedLoading (state) {
      state.workerArchived.loading()
    },
    setWorkerArchivedLoaded (state, data) {
      state.workerArchived.loaded(data)
    },
    addWorkerArchivedLoaded (state, data) {
      state.workerArchived.loadMore(data)
    },
    setWorkerActiveLoading (state) {
      state.workerActive.loading()
    },
    setWorkerActiveLoaded (state, data) {
      state.workerActive.loaded(data)
    },
    addWorkerActiveLoaded (state, data) {
      state.workerActive.loadMore(data)
    },
    setWorkerCompletedLoading (state) {
      state.workerCompleted.loading()
    },
    setWorkerCompletedLoaded (state, data) {
      state.workerCompleted.loaded(data)
    },
    addWorkerCompletedLoaded (state, data) {
      state.workerCompleted.loadMore(data)
    }
  },
  actions: {
    async loadClientPosted ({ commit }, { limit = 10, offset = 0 }) {
      commit('setClientPostedLoading')
      const { pagination, jobs } = await getMyCustomerJobList({ limit, offset, stages: [STAGE_NEW, STAGE_STARTED] })
      commit('setClientPostedLoaded', { pagination, values: jobs.map(MyCustomerJobListItem.fromServer) })
    },
    async loadMoreClientPosted ({ commit }, { limit = 10, offset = 0 }) {
      const { pagination, jobs } = await getMyCustomerJobList({ limit, offset, stages: [STAGE_NEW, STAGE_STARTED] })
      commit('addClientPostedLoaded', { pagination, values: jobs.map(MyCustomerJobListItem.fromServer) })
    },
    async loadClientActive ({ commit }, { limit = 10, offset = 0 }) {
      commit('setClientActiveLoading')
      const { pagination, jobs } = await getMyCustomerJobList({
        limit,
        offset,
        stages: [STAGE_IN_PROGRESS, STAGE_BLOCKED_BY_FREELANCER],
      })
      commit('setClientActiveLoaded', { pagination, values: jobs.map(MyCustomerJobListItem.fromServer) })
    },
    async loadMoreClientActive ({ commit }, { limit = 10, offset = 0 }) {
      const { pagination, jobs } = await getMyCustomerJobList({
        limit,
        offset,
        stages: [STAGE_IN_PROGRESS, STAGE_BLOCKED_BY_FREELANCER],
      })
      commit('addClientActiveLoaded', { pagination, values: jobs.map(MyCustomerJobListItem.fromServer) })
    },
    async loadClientCompleted ({ commit }, { limit = 10, offset = 0 }) {
      commit('setClientCompletedLoading')
      const { pagination, jobs } = await getMyCustomerCompletedJobList({ limit, offset })
      commit('setClientCompletedLoaded', { pagination, values: jobs.map(MyCustomerJobListItem.fromServer) })
    },
    async loadMoreClientCompleted ({ commit }, { limit = 10, offset = 0 }) {
      const { pagination, jobs } = await getMyCustomerCompletedJobList({ limit, offset })
      commit('addClientCompletedLoaded', { pagination, values: jobs.map(MyCustomerJobListItem.fromServer) })
    },
    async loadClientAll ({ commit }, { limit = 10, offset = 0 }) {
      commit('setClientAllLoading')
      const { pagination, jobs } = await getMyCustomerJobList({ limit, offset })
      commit('setClientAllLoaded', { pagination, values: jobs.map(MyCustomerJobListItem.fromServer) })
    },
    async loadMoreClientAll ({ commit }, { limit = 10, offset = 0 }) {
      const { pagination, jobs } = await getMyCustomerJobList({ limit, offset })
      commit('addClientAllLoaded', { pagination, values: jobs.map(MyCustomerJobListItem.fromServer) })
    },
    async loadWorkerAll ({ commit }, { limit = 10, offset = 0 }) {
      commit('setWorkerAllLoading')
      const { pagination, applications } = await getWorkerList({
        limit,
        offset,
      })
      commit('setWorkerAllLoaded', { pagination, values: applications.map(JobApplication.fromServer) })
    },
    async loadMoreWorkerAll ({ commit }, { limit = 10, offset = 0 }) {
      const { pagination, applications } = await getWorkerList({
        limit,
        offset
      })
      commit('addWorkerAllLoaded', { pagination, values: applications.map(JobApplication.fromServer) })
    },
    async loadWorkerNegotiations ({ commit }, { limit = 10, offset = 0 }) {
      commit('setWorkerNegotiationsLoading')
      const { pagination, applications } = await getWorkerList({
        limit,
        offset,
        jobStages: [STAGE_NEW, STAGE_STARTED],
        applicationStatuses: [JobApplicationStatuses.NEW],
      })
      commit('setWorkerNegotiationsLoaded', { pagination, values: applications.map(JobApplication.fromServer) })
    },
    async loadMoreWorkerNegotiations ({ commit }, { limit = 10, offset = 0 }) {
      const { pagination, applications } = await getWorkerList({
        limit,
        offset,
        jobStages: [STAGE_NEW, STAGE_STARTED],
        applicationStatuses: [JobApplicationStatuses.NEW],
      })
      commit('addWorkerNegotiationsLoaded', { pagination, values: applications.map(JobApplication.fromServer) })
    },
    async loadWorkerActive ({ commit }, { limit = 10, offset = 0 }) {
      commit('setWorkerActiveLoading')
      const { pagination, applications } = await getWorkerList({
        limit,
        offset,
        jobStages: [STAGE_IN_PROGRESS, STAGE_BLOCKED_BY_FREELANCER],
        applicationStatuses: [JobApplicationStatuses.NEW],
      })
      commit('setWorkerActiveLoaded', { pagination, values: applications.map(JobApplication.fromServer) })
    },
    async loadMoreWorkerActive ({ commit }, { limit = 10, offset = 0 }) {
      const { pagination, applications } = await getWorkerList({
        limit,
        offset,
        jobStages: [STAGE_IN_PROGRESS, STAGE_BLOCKED_BY_FREELANCER],
        applicationStatuses: [JobApplicationStatuses.NEW],
      })
      commit('addWorkerActiveLoaded', { pagination, values: applications.map(JobApplication.fromServer) })
    },
    async loadWorkerCompleted ({ commit }, { limit = 10, offset = 0 }) {
      commit('setWorkerCompletedLoading')
      const { pagination, applications } = await getWorkerList({
        limit,
        offset,
        jobStages: [STAGE_COMPLETED, STAGE_DISPUTED, STAGE_DEADLINE_OVERDUE],
        applicationStatuses: [JobApplicationStatuses.ARCHIVED],
      })
      commit('setWorkerCompletedLoaded', { pagination, values: applications.map(JobApplication.fromServer) })
    },
    async loadMoreWorkerCompleted ({ commit }, { limit = 10, offset = 0 }) {
      const { pagination, applications } = await getWorkerList({
        limit,
        offset,
        jobStages: [STAGE_COMPLETED, STAGE_DISPUTED, STAGE_DEADLINE_OVERDUE],
        applicationStatuses: [JobApplicationStatuses.ARCHIVED],
      })
      commit('addWorkerCompletedLoaded', { pagination, values: applications.map(JobApplication.fromServer) })
    },
    async loadWorkerArchived ({ commit }, { limit = 10, offset = 0 }) {
      commit('setWorkerArchivedLoading')
      const { pagination, applications } = await getWorkerList({
        limit,
        offset,
        jobStages: [
          STAGE_NEW,
          STAGE_STARTED,
          STAGE_IN_PROGRESS,
          STAGE_BLOCKED_BY_FREELANCER
        ],
        applicationStatuses: [JobApplicationStatuses.ARCHIVED],
      })
      commit('setWorkerArchivedLoaded', { pagination, values: applications.map(JobApplication.fromServer) })
    },
    async loadMoreWorkerArchived ({ commit }, { limit = 10, offset = 0 }) {
      const { pagination, applications } = await getWorkerList({
        limit,
        offset,
        jobStages: [
          STAGE_NEW,
          STAGE_STARTED,
          STAGE_IN_PROGRESS,
          STAGE_BLOCKED_BY_FREELANCER
        ],
        applicationStatuses: [JobApplicationStatuses.ARCHIVED],
      })
      commit('addWorkerArchivedLoaded', { pagination, values: applications.map(JobApplication.fromServer) })
    },
  }
})
