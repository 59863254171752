import cloneDeep from 'lodash/cloneDeep'
import maxBy from 'lodash/maxBy'
import unescape from 'lodash/unescape'
import { JobApplicationStatuses } from '@/constants/job/jobApplicationStatuses'
import { parseSlug } from '@/utils-ts/parser'
import UserInfo, { UserInfoFromServer } from '@/models-ts/user/UserInfo'
import JobApplicationOffer, { JobApplicationOfferFromServer } from './JobApplicationOffer'
import { addDays, convertToLocal, getDateFromString } from '@/utils/date'
import { JobOfferStages } from '@/constants/job/jobOfferStages'
import {
  JobStage,
  STAGE_COMPLETED,
  STAGE_DEADLINE_OVERDUE,
  STAGE_DISPUTED,
  STAGE_NEW,
  STAGE_STARTED
} from '@/constants/job/jobStages'
import { Blockchain } from '@/constants/blockchain'
import { JobStatus } from '@/constants/job/jobStatuses'
import { FileFromServer } from '../File'
import { FileCategories } from '@/constants/backend/FileCategories'
import { getUrl } from '@/utils/file'
import JobApplicationMeta from './JobApplicationMeta'
import { formatCurrency, formatUsd } from '@/utils/moneyFormat'
import { getCurrency } from '@/utils-ts/currencies'
import { CURRENCY_FIELD_BACKEND_ID } from '@/constants/currency'
import { JobApplicationReview, JobApplicationReviewFromServer } from '@/models-ts/job/JobApplicationReview'

export default class JobApplication {
  id: number
  status: JobApplicationStatuses
  comment: string
  offer: JobApplicationOffer | null
  deadline: number
  budget: string
  isRead: boolean
  createdAt: string
  job: {
    id: number
    slug: string
    status: JobStatus
    stage: JobStage
    name: string
    budget: string
    deliveryTimeAt?: string | null
    blockchain: Blockchain
    currency: number | null
    escrowBalance?: string | null
    escrowBalanceDecimals?: string | null
    escrowBalanceUsd?: string | null
    estimate: number | null
    inProgressAt: string | null
    isDone: boolean
    contractVersion: number | null
    invoiceUrl: string | null,
    txIdCreated?: string | null
    txIdCompleted?: string | null
    completedAt?: string | null
    invoiceDocumentUrl?: string
  }
  reviews: Array<JobApplicationReview>
  freelancer: UserInfo | null
  customer: UserInfo | null
  hasOffers: boolean
  meta?: JobApplicationMeta | null

  constructor (data: Partial<JobApplication>) {
    Object.assign(this, cloneDeep(data))
  }

  get currencyModel () {
    return getCurrency({
      blockchain: this.job.blockchain,
      value: this.job?.currency,
      field: CURRENCY_FIELD_BACKEND_ID
    })
  }

  get currencyTokenName () {
    return this.currencyModel
      ? this.currencyModel.name
      : 'N/A'
  }

  get offerBudgetUsdFormatted () {
    if (this.offer?.budget) {
      return formatUsd(this.offer?.budget)
    }
    return 'N/A'
  }

  get budgetFormatted () {
    if (this.job?.escrowBalanceDecimals) {
      return formatCurrency(this.job?.escrowBalanceDecimals, { currency: this.currencyModel })
    }
    return 'N/A'
  }

  get budgetUsdFormatted () {
    if (this.job?.escrowBalanceUsd) {
      return formatUsd(this.job?.escrowBalanceUsd)
    }
    return 'N/A'
  }

  get hasFreelancerLeftReview () {
    return this.reviews
      .some((item: JobApplicationReview) => item.fromUserId === this.freelancer?.id)
  }

  get hasCustomerLeftReview () {
    return this.reviews
      .some((item: JobApplicationReview) => item.fromUserId === this.customer?.id)
  }

  static fromServer (data: JobApplicationFromServer) {
    const offer = maxBy((data.relations?.Offers || [])
      .map(offer => ({
        ...offer,
        createdDate: +(getDateFromString(offer.created_at)),
      })), 'createdDate')

    const invoiceFile = (data.relations.Job.relations?.File || []).find((file: any) => file.category === FileCategories.CAT_GENERATED_PDF)
    const invoiceUrl = invoiceFile ? getUrl(invoiceFile) : null
    const job = data.relations?.Job
    return new JobApplication({
      id: data.id,
      status: data.status,
      isRead: Boolean(data.is_read),
      comment: data.comment || '',
      deadline: data.deadline,
      createdAt: data.created_at,
      budget: data?.budget?.amount,
      offer: offer ? JobApplicationOffer.fromServer(offer) : null,
      hasOffers: !!(data.relations?.Offers || []).length,
      reviews: (data.relations?.Job?.relations?.Review || [])
        .map(JobApplicationReview.fromServer),
      job: {
        id: job.id || 0,
        slug: parseSlug(job?.slug),
        stage: job.stage,
        status: job.status,
        name: unescape(job.name),
        deliveryTimeAt: job.delivery_time_at,
        budget: job.budget ? String(Number(job.budget).toFixed(2)) : '0.00',
        blockchain: job.blockchain,
        currency: job.currency,
        escrowBalance: job?.escrow_balance,
        escrowBalanceDecimals: job.escrow_balance_decimal,
        escrowBalanceUsd: job.escrow_balance_usd,
        estimate: data.deadline ? data.deadline / 86400 : 0, // from seconds to day
        inProgressAt: job.in_progress_at ? convertToLocal(job.in_progress_at) : null,
        isDone: Boolean(job.is_done || false),
        contractVersion: job.contract_version,
        invoiceUrl,
        txIdCreated: job.txid_created,
        txIdCompleted: job.txid_completed,
        completedAt: job.completed_at
      },
      customer: data.relations?.Customer ? UserInfo.fromServer(data.relations?.Customer) : null,
      freelancer: data.relations?.Freelancer ? UserInfo.fromServer(data.relations?.Freelancer) : null,
      meta: data.tabs_meta
    })
  }

  get estimatedDeadline () {
    if (this.wasStarted) {
      const inProgressAt = this.job.inProgressAt || ''
      const estimate = this.job.estimate || 0
      return addDays(inProgressAt, estimate)
    }
  }

  get deadlineInDays () {
    return (this.deadline || 0) / 86400
  }

  get wasStarted () {
    return ![STAGE_NEW, STAGE_STARTED].includes(this.job.stage)
  }

  get isCompleted () {
    return [STAGE_COMPLETED, STAGE_DEADLINE_OVERDUE, STAGE_DISPUTED].includes(this.job.stage) &&
      this.offer?.stage === JobOfferStages.ACCEPTED_BY_CUSTOMER
  }
}

export type JobApplicationFromServer = {
  id: number
  status: JobApplicationStatuses
  comment?: string
  deadline: number
  budget: {
    amount: string,
    currency: string
  },
  is_read: number
  tabs_meta?: JobApplicationMeta | null
  created_at: string
  relations: {
    Offers?: Array<JobApplicationOfferFromServer>
    Job: {
      id: number
      stage: JobStage
      status: JobStatus
      slug: string
      name: string
      budget: string
      delivery_time_at?: string | null
      blockchain: Blockchain
      currency: number | null
      escrow_balance?: string | null
      escrow_balance_decimal?: string | null
      escrow_balance_usd?: string | null
      deadline: number
      in_progress_at: string | null
      is_done: number
      contract_version: number | null
      txid_created?: string | null
      txid_completed?: string | null
      completed_at?: string | null
      relations?: {
        File: Array<FileFromServer>,
        Review: Array<JobApplicationReviewFromServer>
      }
    }
    Freelancer?: UserInfoFromServer
    Customer?: UserInfoFromServer
  }
}
